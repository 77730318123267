
import { Component, Vue } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { System } from '@/modules/system'

@Component
export default class AboutMenu extends Vue {
  dialog = false;
  system = $app.module(System);

  async open() {
    $app.loading(true)
    try {
      await this.system.$store.loadClinics()
      this.dialog = true
    } catch (error) {
      $app.pushError(error)
    }
    $app.loading(false)
  }

  async change(id: number) {
    this.dialog = false
    $app.loading(true)
    try {
      await this.system.$store.selectClinic(id)
      this.$router.push('/')
      this.$router.go(0)
    } catch (error) {
      $app.pushError(error)
    }
  }
}
